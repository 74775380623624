import './styles/App.css'
import { useTranslation } from 'react-i18next'
import AppRouter from './Router'
import { createContext, useEffect, useLayoutEffect, useState } from 'react'
import ModalContext from './Context/ModalContext'
import DateContextProvider from './Context/DateContext'
import { BrowserRouter, useLocation } from 'react-router-dom'
import ShowMessage from './Context/ShowMessage'
import MyselfContext from './Context/MyselfContext'
import 'swiper/css';
import AppRouterVideo from './Router/RouteVideo'


export const SidebarContext = createContext<{
  collapsed: boolean
  toggleCollapsed: () => void
}>({
  collapsed: false,
  toggleCollapsed: () => null
})

function App() {
  const { t, i18n } = useTranslation()
  const [collapsed, setCollapsed] = useState(true)
  const toggleCollapsed = () => {
    setCollapsed(!collapsed)
  }
  const  pathname  = window.location.pathname;

  return (
    <div className="lms-container">
      <BrowserRouter>
        <MyselfContext>
          <DateContextProvider>
            <ShowMessage>
              <ModalContext>
                <SidebarContext.Provider
                  value={{
                    collapsed,
                    toggleCollapsed
                  }}
                >
                  {
                    pathname.includes("/video-lesson")? <AppRouterVideo /> : <AppRouter />
                  }
                </SidebarContext.Provider>
              </ModalContext>
            </ShowMessage>
          </DateContextProvider>
        </MyselfContext>
      </BrowserRouter>
    </div>
  )
}

export default App
