// import { BrowserRouter, Navigate, Route, Routes } = lazy(() => import('react-router-dom'))
import { Suspense, lazy, useEffect } from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'
import UserService from './services/UserService'
import NotFound, { NotFoundEmpty } from './components/Notfound'

import { ADMIN, LECTURER, STUDENT } from './types'
import { VideoComponent } from './containers/Student/VideoComponent'

const Layout = lazy(() => import('./layout/Layout'))
const CreateLessonPage = lazy(() => import('./pages/Teacher/CreateLessonPage'))
const Homepage = lazy(() => import('./pages/Homepage'))
const DashboardManagerUser = lazy(() => import('./pages/Manager/DashboardManagerUserPage'))
const DashboardManagerClasses = lazy(() => import('./pages/Manager/DashboardManagerClassesPage'))
const StudentFacultyPage = lazy(() => import('./pages/Student/StudentFacultyPage'))
const ManagerNoticationPage = lazy(() => import('./pages/Manager/ManagerNoticationPage'))
const ManagerNewsPage = lazy(() => import('./pages/Manager/ManagerNewsPage'))
const ManagerAddNewsPage = lazy(() => import('./pages/Manager/ManagerAddNewsPage'))
const CreateQuizPage = lazy(() => import('./pages/Teacher/CreateQuizPage'))
const TeacherFacultyPage = lazy(() => import('./pages/Teacher/TeacherFacultyPage'))
const CourseTeacherDetailPage = lazy(() => import('./pages/Teacher/CourseTeacherDetailPage'))
const CourseTeacherPage = lazy(() => import('./pages/Teacher/CourseTeacherPage'))
const CourseTeacherDetailStudentPage = lazy(() => import('./pages/Teacher/CourseTeacherDetailStudentPage'))
const EssayExamPage = lazy(() => import('./pages/Teacher/EssayExamPage'))
const EditQuizPage = lazy(() => import('./pages/Manager/EditQuizPage'))
const QuizTestPage = lazy(() => import('./pages/Student/QuizTestPage'))
const EssayExamStudentPage = lazy(() => import('./pages/Student/EssayExamStudentPage'))
const MultipleChoiceStudentPage = lazy(() => import('./pages/Student/MultipleChoiceStudentPage'))
const UserManualPage = lazy(() => import('./pages/Manager/UserManualPage'))
const ManagerRequestsPage = lazy(() => import('./pages/Manager/ManagerRequestsPage'))
const DoEssayExamPage = lazy(() => import('./pages/Student/DoEssayExamPage'))
const StudentUserManualPage = lazy(() => import('./pages/Student/StudentUserManualPage'))
const ListTrainningSimple = lazy(() => import('./pages/ListTrainningSimple'))
const LessonDetail = lazy(() => import('./containers/Student/LessonDetail'))
const ListCourseMajors = lazy(() => import('./containers/HomeContainer/ListCourseMajors'))
const ManagerDepartment = lazy(() => import('./containers/Manager/ManagerDepartment'))
const ManagerUpdateClassCotainer = lazy(() => import('./containers/Manager/ManagerUpdateClassCotainer'))
const ManagerClassesContainer = lazy(() => import('./containers/Manager/ManagerClassesContainer'))
const CreateEssayExam = lazy(() => import('./containers/Teacher/CreateEssayExam'))
const ActivityHistory = lazy(() => import('./containers/common/HistoryActivityContainer'))
const ExamsCourseContainer = lazy(() => import('./containers/Teacher/ExamsCourseContainer'))
const HomeNewpage = lazy(() => import('./pages/HomeNewpage'))
const ViewNews = lazy(() => import('./pages/ViewNews'))
const SearchPage = lazy(() => import('./pages/SearchPage'))
const ManagerClassDepartment = lazy(() => import('./containers/Manager/ManagerClassDepartment'))
const ManagerClassDetail = lazy(() => import('./containers/Manager/ManagerClassDetail'))
const ViewFile = lazy(() => import('./components/ViewFile'))
const ManagerAddClass = lazy(() => import('./containers/Manager/ManagerAddClass'))
const EssayExamStudentDetail = lazy(() => import('./containers/Student/EssayExamStudentDetail'))
const ViewScoresExam = lazy(() => import('./containers/Teacher/ViewScoresExam'))
const QuestionsBank = lazy(() => import('./containers/Teacher/QuestionsBank').then((module) => ({ default: module.QuestionsBank })))
const ManagerSubjects = lazy(() => import('./containers/Manager/ManagerSubjects'))

const CoursePage = lazy(() => import('./pages/Student/CoursePage'))
const CoursePageFolder = lazy(() => import('./pages/Student/CoursePageFolder'));
const AppRouter = () => {
  const { pathname } = useLocation()
  useEffect(() => {
    document.documentElement.scrollTo({ top: 0, behavior: 'smooth' })
  }, [pathname])
  return (
    <Suspense fallback={'loading...'}>
      <Layout>
        <Routes>
          <Route
            path="/"
            element={
              <Suspense>
                <Homepage />
              </Suspense>
            }
          />
          <Route
            path="/home-new"
            element={
              <Suspense>
                <HomeNewpage />
              </Suspense>
            }
          />
          <Route
            path="/home-new/view/:id"
            element={
              <Suspense>
                <ViewNews />
              </Suspense>
            }
          />
          <Route
            path="/home-list-training"
            element={
              <Suspense>
                <ListTrainningSimple />
              </Suspense>
            }
          />
          <Route
            path="/home-departments/:id"
            element={
              <Suspense>
                <ListCourseMajors />
              </Suspense>
            }
          />
          <Route
            path="/history-activity"
            element={
              UserService.hasRole([STUDENT, LECTURER]) ? (
                <Suspense>
                  <ActivityHistory />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          {/* manager */}
          <Route
            path="/manager-users"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  {' '}
                  <DashboardManagerUser />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-classes"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerClassDepartment />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/manager-classes/add"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  {' '}
                  <ManagerAddClass />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-classes/update/:id"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerAddClass />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/manager-classes/:id"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  {' '}
                  <ManagerClassDetail />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route path="/search" element={<SearchPage />} />
          {/* manager */}
          <Route
            path="/manager-users"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  {' '}
                  <DashboardManagerUser />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/manager-notify"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerNoticationPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-news"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerNewsPage />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-news/add"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  {' '}
                  <ManagerAddNewsPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-news/edit/:id"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerAddNewsPage />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/manager-request"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerRequestsPage />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/user-manual"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <UserManualPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-department/*"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  {' '}
                  <ManagerDepartment />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-department/:departmentId/:subjectId"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <DashboardManagerClasses />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-department/subject/:id"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerSubjects />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-department/:departmentId/:subjectId/add-classes"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  {' '}
                  <ManagerClassesContainer />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/manager-department/:departmentId/:subjectId/update-course/:courseId"
            element={
              UserService.hasRole([ADMIN]) ? (
                <Suspense>
                  <ManagerUpdateClassCotainer />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          {/* student */}

          <Route
            path="/faculty/*"
            element={
              UserService.hasRole([STUDENT]) ? (
                <StudentFacultyPage />
              ) : UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <TeacherFacultyPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/student-essay-exam"
            element={
              <Suspense>
                <EssayExamStudentPage />
              </Suspense>
            }
          />
          <Route
            path="/student-multiple-choice-exam"
            element={
              <Suspense>
                <MultipleChoiceStudentPage />
              </Suspense>
            }
          />
          <Route
            path="/student-do-essay-exam/:id"
            element={
              UserService.hasRole([STUDENT, LECTURER]) ? (
                <Suspense>
                  {' '}
                  <DoEssayExamPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/faculty/lesson-detail/:id"
            element={
              UserService.hasRole([STUDENT, LECTURER, ADMIN]) ? (
                <Suspense>
                  <LessonDetail />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/student-exam/:id/:idCourse"
            element={
              <Suspense>
                <EssayExamStudentDetail />
              </Suspense>
            }
          />

          {/* <Route path="/student-essay-exam" element={<EssayExamStudentPage />} /> */}
          <Route
            path="/student-do-essay-exam/:id"
            element={
              UserService.hasRole([STUDENT]) ? (
                <Suspense>
                  <DoEssayExamPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          {/* <Route path="/student-multiple-choice-exam" element={<MultipleChoiceStudentPage />} /> */}

          <Route
            path="/lms-help"
            element={
              UserService.hasRole([STUDENT, LECTURER]) ? (
                <Suspense>
                  {' '}
                  <StudentUserManualPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/teacher-lsm-help"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <StudentUserManualPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/questions-list"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  {' '}
                  <QuestionsBank />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/faculty/courseView/:id"
            element={
              UserService.hasRole([STUDENT, LECTURER, ADMIN]) ? (
                <Suspense>
                  <CoursePage />
                </Suspense>
              ) : (
                <NotFoundEmpty />
              )
            }
          />
          <Route path="/test-file" element={<ViewFile url="" />} />
          {/* teacher */}
          <Route
            path="/faculty/createLesson/:course/:folderId"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <CreateLessonPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/faculty/update-lesson/:lessonId"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <CreateLessonPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/teacher-course"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <CourseTeacherPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/teacher-course/:id"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <CourseTeacherDetailPage />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/teacher-course/student-detail/:idCourse/:idStudent"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <CourseTeacherDetailStudentPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/teacher-essay-exam/:id"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <DoEssayExamPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/create-essay-exam/:id/:folderId"
            element={
              true ? (
                <Suspense>
                  <CreateEssayExam />{' '}
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/edit-essay-exam/:id/:idExam"
            element={
              true ? (
                <Suspense>
                  {' '}
                  <CreateEssayExam />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/student-view-exam/:examId/:courseId"
            element={
              UserService.hasRole([LECTURER, STUDENT]) ? (
                <Suspense>
                  <EssayExamPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/teacher-course/view-scores/:id"
            element={
              <Suspense>
                <ViewScoresExam />
              </Suspense>
            }
          />

          <Route
            path="/student-exam/detail/:courseId/:examId/:studentId"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <EssayExamPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/teacher-course/exam-course/:courseId"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <ExamsCourseContainer />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />

          <Route
            path="/faculty/courseView/:id"
            element={
              UserService.hasRole([STUDENT, LECTURER, ADMIN]) ? (
                <Suspense>
                  <CoursePage />
                </Suspense>
              ) : (
                <NotFoundEmpty />
              )
            }
          />
          <Route
            path="/faculty/courseView/:courseId/:type/:folderId"
            element={
              UserService.hasRole([STUDENT, LECTURER, ADMIN]) ? (
                <Suspense>
                  <CoursePageFolder />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/:courseId/create-quiz/:folderId"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <CreateQuizPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/:courseId/edit-quiz/:quizId"
            element={
              UserService.hasRole([LECTURER]) ? (
                <Suspense>
                  <EditQuizPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route
            path="/:courseId/quiz-test/:quizId"
            element={
              UserService.hasRole([STUDENT]) ? (
                <Suspense>
                  {' '}
                  <QuizTestPage />
                </Suspense>
              ) : (
                <NotFound />
              )
            }
          />
          <Route path="/404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Layout>
    </Suspense>
  )
}
export default AppRouter
