import { createContext, ReactNode, useEffect, useState } from 'react'
import Modal from '../components/Modal'
import { useLocation } from 'react-router-dom'
type Props = {
  children: ReactNode
}
type _contextModal = {
  isShowModal: boolean
  showModal: (isCheck: boolean) => void
  setModal: (element: JSX.Element) => void
}

export const ContextModal = createContext<_contextModal>({
  isShowModal: false,
  showModal: (isCheck: boolean) => {},
  setModal: (element: JSX.Element) => {}
})

export default function ModalContext({ children }: Props) {
  const location = useLocation()
  const [isShowModal, setShowModal] = useState(false)
  const [NodeModal, setNodeModal] = useState<JSX.Element>(<></>)
  const showModal = (isCheck: boolean) => {
    setShowModal(isCheck)
  }

  const setModal = (element: JSX.Element) => {
    setNodeModal(element)
  }

  useEffect(() => {
    return () => {
      setShowModal(false)
    }
  }, [location.pathname, location.search])

  return (
    <ContextModal.Provider
      value={{
        isShowModal,
        showModal,
        setModal
      }}
    >
      {children}
      <Modal>{NodeModal}</Modal>
    </ContextModal.Provider>
  )
}
